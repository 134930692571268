import {isRouteErrorResponse, useRouteError} from '@remix-run/react';
import {captureRemixErrorBoundaryError} from '@sentry/remix';
import {IconMoodSadDizzy} from '@tabler/icons-react';

import {ErrorPage} from '../ErrorPage';
import {AppError} from './errors';

const messages: Record<AppError, string> = {
  [AppError.NOT_FOUND]:
    'Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.',
  [AppError.FORBIDDEN]: 'Unfortunately, you do not have sufficient permissions to access this page.',
  [AppError.UNAUTHORIZED]: 'You are not authorized to view this page.',
  [AppError.BAD_REQUEST]: 'Invalid request, please check the parameters and try again.',
  [AppError.SYNC_NOT_FOUND]:
    'Unfortunately, the data for this blockchain is not available because it has not been synced yet.',
  [AppError.BLOCKCHAIN_NOT_FOUND]:
    'Unfortunately, the data for this blockchain was not found. You may have mistyped the address, or the page has been moved to another URL.',
  [AppError.BLOCKCHAIN_NOT_READY]: 'We have not yet prepared this blockchain for observations. Please come back later.',
};

export function AppErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    const {status, data} = error;
    const text = typeof data === 'string' && data;
    const message: string | undefined = messages[text as AppError];

    const errorResponses: Record<number, {title: string; description: string; errorCode: string}> = {
      404: {
        title: text || AppError.NOT_FOUND,
        description: message || messages[AppError.NOT_FOUND],
        errorCode: '404',
      },
      403: {
        title: text || AppError.BLOCKCHAIN_NOT_READY,
        description: message || messages[AppError.BLOCKCHAIN_NOT_READY],
        errorCode: '403',
      },
      401: {
        title: text || AppError.UNAUTHORIZED,
        description: message || messages[AppError.UNAUTHORIZED],
        errorCode: '401',
      },
      400: {
        title: text || AppError.BAD_REQUEST,
        description: message || messages[AppError.BAD_REQUEST],
        errorCode: '400',
      },
    };

    const response = errorResponses[status];

    if (response) {
      return <ErrorPage {...response} />;
    }
  }

  return (
    <ErrorPage
      errorCode={<IconMoodSadDizzy size="12rem" />}
      title="Unexpected error occured"
      description="Blockchain page is unavailable due to unexpected error. We are already aware of this issue and working on fixing it."
      redirectTo="/"
    />
  );
}
